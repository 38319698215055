<template>
  <div
    :class="[
      { 'sale-page-one': $route.path == '/sale-page-one' },
      { 'sale-page-two': $route.path == '/sale-page-two' },
    ]"
  >
    <!-- 最顶部 -->
    <div class="bg_f5  top-bar">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class=" d-flex justify-space-between align-center">
            <div class="mr-2  d-flex justify-space-between align-center">
              <v-img
                width="20"
                src="@/assets/images/icon/icon_iphone.svg"
              >
              </v-img>
              <span class="mr-2 ml-2 " color="#999999">{{ $t("应用下载") }}</span>
              <a href="https://download.taocarts.com/taocartsapk/fanliapp.apk">
              <v-img
                width="20"
                src="@/assets/images/icon/icon_ios.svg"
              >
              </v-img>
              </a>
              <a href="https://download.taocarts.com/taocartsapk/fanliapp.apk">
                <v-img
                  width="20"
                  src="@/assets/images/icon/icon_android.svg"
                >
                </v-img>
              </a>
            </div>
            <div class="ml-8  d-flex justify-space-between align-center">
              <a href="https://chromewebstore.google.com/detail/chi%E1%BA%BFt-kh%E1%BA%A5u-taobao-1688-pi/jocmnnhpkodekainkcchihiglkhedffa?hl=vi" target="_blank" rel="noopener noreferrer">
                <v-img
                  width="180"
                  src="@/assets/images/other/fanlicj.png"
                >
                </v-img>
              </a>
            </div>
          </v-toolbar-title>
          
          <v-toolbar-title>
            <span  v-if="!userinfos">
               <span
              class=" mr-5 d-none d-sm-inline-block cursor"
              @click="showlogin"
             
              >{{ $t("登录") }}</span
            >
            <span
              class=" mr-5 d-none d-sm-inline-block cursor"
              @click="showreg"
            
              >{{ $t("注册") }}</span
            >
            </span>
            
            <span v-else>
              <router-link to="/user/accountSet" class="d-none d-sm-inline-block">
                <v-avatar size="26" class="me-2">
                  <img :src="userinfos.avatar" alt="" v-if="userinfos.avatar" />
                  <img src="@/assets/images/avatar.png" alt="" v-else />
                </v-avatar>
                <span
                  class="
                    text-sm
                    mr-5
                    d-md-inline-block
                    cursor
                    d-none
                  "
                  >{{ userinfos.nick_name }}</span
                >
              </router-link>
              <span
                class=" text-sm mr-5 d-none d-sm-inline-block cursor"
                @click="logout"
                >{{ $t("退出") }}</span
              >
            </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span style="margin-right:1rem;margin-left:1rem;">|</span>
              </template>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="transparent" depressed  v-bind="attrs" v-on="on" small>
                  <span>{{$t("个人中心")}}</span>
                  <v-icon right small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="user==null" to class>
                  <v-list-item-content class="pt-0 pb-0">
                    <p class="mt-3 ml-2">{{ $t("欢迎你的到来") }}!</p>
                    <div class="mt-3 d-flex mb-3">
                      <v-btn
                        class="primary"
                        depressed
                        small
                        to="/sign-in"
                      >
                        {{ $t("登录") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        class="primary--text"
                        depressed
                        small
                        to="/sign-up"
                        >{{ $t("注册") }}</v-btn
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="!user " />
                <v-list-item
                  v-for="(item, index) in langitems.list"
                  :key="index"
                >
                  <v-list-item-title
                    class="d-flex align-center cursor"
                    @click="changedef(item, 'lang_')"
                  >
                    {{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span style="margin-right:1rem;margin-left:1rem;">|</span>
              </template>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="transparent" depressed v-bind="attrs" v-on="on" small>
                  <v-avatar size="20" class="mr-2">
                    <v-img :src="rateitems.def.icon" />
                  </v-avatar>
                  <span class="color8e font16">
                    {{ $t(rateitems.def.name) }}
                  </span>
                  <v-icon right small>mdi-chevron-down</v-icon>
                </v-btn>
                
              </template>
              <v-list>
                
                <v-list-item
                  v-for="(item, index) in rateitems.list"
                  :key="index"
                >
                  <v-list-item-title
                    class=" cursor"
                    @click="changedef(item, 'rete_')"
                    >
                    <v-avatar size="20" class="mr-2">
                    <v-img :src="item.icon" />
                  </v-avatar>
                    {{ item.name }}
                  </v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar-title>
        </div>
      </v-container>
    </div>

    <v-app-bar
      app
      scroll-off-screen
      class="sub-topbar py-1 d-none d-md-block"
      color="white "
    >
      <v-container style="height:100px">
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/">
            <div>
              <v-img
                class="me-2 logo d-md-block d-none"
                max-width="130" 
                :src="weblogo"
                alt="logo"
              ></v-img>

            </div>
            </router-link>
          </v-toolbar-title>
          <v-col cols="8" class="d-flex justify-center">
            <v-card
              flat
              class="
                rounded-l-xl rounded-r-xl
                searchMain-input
                pl-4
                d-flex
                align-center
              "
              width="70%"
            >
              <input
                height="32"
                id="autocomplete-box"
                autocomplete="off"
                :placeholder="$t('输入任意商品链接开始返利~')"
                class="pr-1 text-no-wrap text-truncate"
                width="98%"
                v-model="keyword"
                @keydown.enter="toserach"
              />
              <v-btn
                class=""
                rounded
                depressed
                height="42"
                width="80"
                color="primary"
                @click="toserach"
              >
              <div>
                <v-img
                  width="30"
                  height="30"
                  class=""
                  src="@/assets/images/icon/icon_search.svg"
                  alt=""
                ></v-img>

              </div>
                <!-- <v-icon color="#fff" size="28">mdi-magnify</v-icon> -->
              </v-btn>
            </v-card>
          </v-col>
          <div class="text-left col-md-2 d-flex">
            <div class="d-flex flex-column align-center cursor-pointer"  @click="gopage('/infos/news', '1')">
              <v-badge bordered color="error" :content="noticenum" overlap>
                <v-btn
                  elevation="0"
                  fab
                  width="30"
                  height="30"
                  color=" lighten-2"
                  small
                >
                  <v-img
                    width="30"
                    height="30"
                    class=""
                    src="@/assets/images/icon/icon_message.svg"
                    alt=""
                  ></v-img>
                </v-btn>
              </v-badge>
              <div>{{$t('消息通知')}}</div>
            </div>
            <div  class="d-flex flex-column align-center ml-10 cursor-pointer" @click="gopage('/user-index')">
              <v-btn
                elevation="0"
                fab
                width="30"
                height="30"
                color=" lighten-2 "
                small
              >
                <v-img
                  class=""
                  width="30"
                  height="30"
                  src="@/assets/images/icon/icon_user.svg"
                  alt=""
                ></v-img>
              </v-btn>
              <div>{{$t('个人中心')}}</div>
            </div>
          </div>
        </div>
        <v-row class="justify-center" >

          <v-col cols="12"  align="center" :class="langIdent=='vi' ? 'navTabss' : 'navTabs'" >
            <ul  class="mt-4 navbar-nav navigation-navbar d-flex flex-row justify-space-around">
              <v-hover v-slot="{ hover }" v-for="(item ,index) in tabs" :key="index">
                <li class="nav-item   d-flex align-center"  @click.stop.prevent="changeTab(item , index)">
                  <span  v-if="item.name" class="d-flex align-center">
                    <v-img 
                      class=""
                      width="20"
                      height="20"
                      :src="hover || item.isSelect ? item.iconpath_ : item.iconpath"
                      alt=""
                    ></v-img>
                    <a  :class="[ hover || item.isSelect ? 'active' : '']"  class="nav-link"  href="#">{{$t(item.name)}}</a>
                  </span>
                  <span v-else>/</span>
                </li>
              </v-hover>
            </ul>
          </v-col>
        </v-row>

      </v-container>
      
    </v-app-bar>
    <v-card class="aside pa-2">
      <v-list class="pt-0 pb-0">
        <div v-show="asideShow" class="aside-style">
          <div  class="pl-2 pr-2 mb-6">
              <router-link to="/download" class="">
                <div>
                  <v-icon>mdi-cellphone</v-icon>
                </div>
                <div  class="font12">{{ $t("App下载")}}</div>
              </router-link>
          </div>
          <div  class="pl-2 pr-2  mb-6">
            <a href="https://chromewebstore.google.com/detail/chi%E1%BA%BFt-kh%E1%BA%A5u-taobao-1688-pi/jocmnnhpkodekainkcchihiglkhedffa?hl=vi" target="_blank" rel="noopener noreferrer">
              <div>
                <v-icon>mdi-google-chrome</v-icon>
              </div>
              <div class="font12">{{ $t("下载插件")}}</div>

            </a>
          </div>
          <div  class="pl-2 pr-2  mb-6">
            <a href="https://zalo.me/0989543464" target="_blank" rel="noopener noreferrer">
              <div>
                <v-icon>mdi-headset</v-icon>
              </div>
              <div  class="font12">{{ $t("在线客服")}}</div>
            </a>
          </div>
          <div  class="pl-2 pr-2 " @click="backTop()">
              <router-link to="" class="">
                <div>
                  <v-icon>mdi-chevron-up-circle-outline</v-icon>
                </div>
                <div  class="font12">{{ $t("回到顶部")}}</div>
              </router-link>
          </div>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import HeaderNavbar from "@/views/home/header-navbar";
import Navbar from "@/views/home/navbar";
export default {
  name: "BaseAppBar",

  components: {
    HeaderNavbar,
    Navbar,
  },
  data() {
    return {
      isToggleNavbar: false,
      asideShow:true,
      scrollTop: 0,
      offsetTop: 0,
      user:'',
      weblogo:"",
      langitems: {
        list: [
          {name:this.$t('个人主页'),id:1 ,path:"/user-index"},
          {name:this.$t('返利订单'),id:2,path:"/order/fanli-order"},
          {name:this.$t('丢失订单'),id:3,path:"/lose-orders"},
          {name:this.$t('邀请好友'),id:4,path:"/StrategyDetailDn?id=36"},
        ],
        def: {},
      },
      tabs:[
        {
          name:this.$t('首页'),
          isSelect:true,
          router:'/',
          iconpath:require("@/assets/images/icon/icon_tab_home.svg") ,
          iconpath_:require("@/assets/images/icon/icon_tab_home_.svg"),
          id:1
        },
        {
          isSelect:false,
          name:'',
          router:'/',
          iconpath:"",
          iconpath_:"",
          id:'first'
        },
        {
          isSelect:false,
          name:this.$t('最新消息'),
          router:'/more-Strategy?index=0',
          iconpath:require("@/assets/images/icon/icon_tab_inv.svg"),
          iconpath_:require("@/assets/images/icon/icon_tab_inv_.svg"),
          id:2
        },
        {
          isSelect:false,
          name:'',
          router:'/',
          iconpath:"",
          iconpath_:"",
          id:'secnd'
        },
        {
          isSelect:false,
          name:this.$t('丢失订单'),
          router:'/lose-orders',
          iconpath:require("@/assets/images/icon/icon_tab_money.svg"),
          iconpath_:require("@/assets/images/icon/icon_tab_money_.svg"),
          id:3
        },
        // {
        //   isSelect:false,
        //   name:'',
        //   router:'/',
        //   iconpath:"",
        //   iconpath_:"",
        //   id:'three'
        // },
        // {
        //   isSelect:false,
        //   name:this.$t('下载应用'),
        //   router:'/more-Strategy?index=1',
        //   iconpath:require('@/assets/images/icon/icon_tab_iphone.svg') ,
        //   iconpath_:require("@/assets/images/icon/icon_tab_iphone_.svg"),
        //   id:4
        // },
        {
          isSelect:false,
          name:'',
          router:'/',
          iconpath:"",
          iconpath_:"",
          id:'four'
        },
        {
          isSelect:false,
          name:this.$t('返利攻略'),
          router:'/fanli-Strategy',
          iconpath:require("@/assets/images/icon/icon_tab_msg.svg"),
          iconpath_:require("@/assets/images/icon/icon_tab_msg_.svg"),
          id:5
        },
      ],
      isnewskey:true,
      noticenum: "0",
      keyword:'',
      rateitems: {
        list: [
          {name:"中文",icon:require("../../assets/images/icon/icon_china.svg") ,id:"zh-CN"},
          {name:"Tiếng Việt",icon:require("../../assets/images/icon/vietnam.svg"),id:'vi'}
        ],
        def: {
          name:"Tiếng Việt",icon:require("../../assets/images/icon/vietnam.svg") 
        },
      },
      userinfos: null,
      Rules: [(v) => !!v || this.$t("必填项")],
      cartnum: "0",
      rate:'2',
      timer: null,
      activeLink: "/",
      rateList: [],
      rateTimer:null,
      langIdent:'zh'

    };
  },
  watch:{
    $route(to, from) {
      // 对路由变化作出响应...
      // console.log(to.path);
      // this.activeLink = to.path;
    },
  },
  mounted() {
    if(this.$store.state.statedeflang){
      this.rateitems.def=this.$store.state.statedeflang

    }
    this.user = this.$store.state.USERINFO;
    this.userinfos = this.$store.state.USERINFO;
    this.noticenum=this.$store.state.msgNum.toString()
    const path = this.$route.fullPath;
    for(const item of this.tabs){
      if(item.router == path){
        item.isSelect = true;
      }else {
        item.isSelect = false;
      }
    }
    this.langIdent=this.$store.state.statedeflang ? this.$store.state.statedeflang.id : 'vi'
    this.getbanner();
  },
  watch: {
    
  },
   computed:{

  },
  methods: {

    getbanner() {
      this.$api.home.banner().then((res) => {
        if (res.status) {
         this.weblogo=res.data.logo[0].value
        }
      });
    },
    toggleNavbar() {
      if (this.$route.path == "/") {
        this.isToggleNavbar = false;
      } else {
        this.isToggleNavbar = !this.isToggleNavbar;
      }
    },
    
    changedef(item, type) {
      if (type === "lang_") {
        if(item.id==4 && !this.user ){
          this.$router.push({ path: '/sign-in' });
        }else {
          this.$router.push({ path: item.path });
        }
      } else {
        this.$i18n.locale = item.id;
        this.$store.commit("setdeflang", item);
        this.$api.home.get_lang({lang:item.id,type:'home'}).then((res)=>{
          if(res.status=='success'){
            let obj={}
            let arr=res.data
            for(var i=0 ; i<arr.length ; i++){
              obj[arr[i].text]=arr[i].to
            }
            this.$store.commit("setLangJson",obj);
          }
        })
        setTimeout((res)=>{
          window.location.reload();
        },800)
      }
      
    },
    showlogin() {
      this.$router.push("/sign-in");
    },
    showreg() {
      this.$router.push("/sign-up");
    },
    logout() {
      this.$store.commit("setUSERINFO", null);
      this.$store.commit("setTOKEN", null);
      this.$store.commit("setcartnum", "0");
      this.$router.push("/sign-in");
      setTimeout(()=>{
        location.reload() 
      },200)
    },
    //搜索
    toserach() {
      let url=""
      url=this.keyword
      const urlreg = "^((https|http|ftp|rtsp|mms)?://)"; //判定是链接
      const regPinduoduo = /yangkeduo/i;
      if (this.keyword.match(urlreg)){
         const result =  regPinduoduo.test(this.keyword);
         if (result){
            this.$router.push({
              path: "/search-product"+"?"+ new Date().getTime(),
              query: { type: 1, url: url ,webtype:'pinduoduo'},
            });
         }else{
            this.$router.push({
              path: "/search-product"+"?"+ new Date().getTime(),
              query: { type: 1, url: url ,webtype:'other'},
            });
         }
      }else{
        this.$router.push({
          path: "/search-product"+"?"+ new Date().getTime(),
          query: { type: 1, url: url ,webtype:'other'},
        });

      }
    },
    //过滤链接中商品ID
    filterGoodId(goodUrl) {
      const urlreg = "^((https|http|ftp|rtsp|mms)?://)"; //判定是链接
      const regtb = new RegExp(/.*(\?id=|&id=|id\/)(\d+)/i); //链接里有ID 正则 针对tb
      const reg1688 = new RegExp(/.*offer(\?id=|id=|id\/|\/){1}(\d+)/i); //链接里有ID 正则 针对1688
      let goodid = "";
      if (goodUrl == "") {
        return;
      }
      if (goodUrl.match(urlreg)) {
        //淘宝链接
        if (
          goodUrl.indexOf("taobao.com") > -1 ||
          goodUrl.indexOf("tmall.com") > -1
        ) {
          const result = goodUrl.match(regtb);
          if (result != null) {
            goodid = unescape(result[2]);
            return goodid;
          }
        } else if (goodUrl.indexOf("1688.com") > -1) {
          const result = goodUrl.match(reg1688);
          if (result != null) {
            goodid = unescape(result[2]);
            return goodid;
          }
        }
      }
    },
    changeTab(val , index){
      this.$router.push({ path: val.router });
      for(const item of this.tabs){
        if(item.router == val.router){
          item.isSelect = true;
        }else {
          item.isSelect = false;
        }
      }
    },
    gopage(url , index){
      this.$router.push({path:url})
    },
    backTop() {
       var timer = setInterval(function () {
          let osTop =
              document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5);
          document.documentElement.scrollTop = document.body.scrollTop =
              osTop + ispeed;
          this.isTop = true;
          if (osTop === 0) {
              clearInterval(timer);
          }
        }, 30);
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.timer);
    clearInterval(this.rateTimer);
    
  },
};
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
a{
  color: #333;
}
.z-999 {
  z-index: 999;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 140px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  .search-bar-dropdown {
    position: absolute;
    right: 0;
    border-radius: 22px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
    box-shadow: none !important;
    @media (max-width: $md) {
      display: none;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.w23 {
  max-width: 23px;
}
.bg_f5 {
  background: #f5f5f5;
}
.searchMain-input {
  border: solid 2px #FFC2B3;
}
#autocomplete-box {
  border: 0 none;
  border-bottom: 1px solid transparent;
  padding: 0 54px 0 10px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  box-sizing: border-box;
  outline: 0;
}
.aside {
  position: fixed;
  z-index: 6;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.aside-style{
    max-width: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.font12{
  font-size: 12px;
}
</style>
<style lang='scss'>
.search-btn {
  margin-top: -6px;
  margin-right: -20px;
  height: 34px !important;
  span {
    font-size: 16px;
  }
}
.navTabs{
  max-width: 60%;
}
.navTabss{
  max-width: 80%;
}
</style>